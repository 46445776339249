<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle == '详情' ? $t('经纪人/服务者.服务者.详情') : $t('通用.修改') }}</b>
    </a-divider>
    <a-form-model v-if="this.formType == 1" ref="form" :model="form" :rules="rules">
      <!--      <a-form-model-item label="用户id,外键{zb_user.id}" prop="userId" >-->
      <!--        <a-input v-model="form.userId" placeholder="请输入用户id,外键{zb_user.id}" />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item :label="$t('经纪人/服务者.服务者.总经理姓名')" prop="president">
        <a-input :disabled="true" v-model="form.president" placeholder="请输入总经理姓名" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.服务者.公司名称')" prop="company">
        <a-input :disabled="true" v-model="form.company" placeholder="请输入公司名称" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.服务者.公司地址')" prop="address">
        <a-input :disabled="true" v-model="form.address" placeholder="请输入公司地址" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.服务者.电话号码')" prop="mobile">
        <a-input :disabled="true" v-model="form.mobile" placeholder="请输入电话号码" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.服务者.电子邮件')" prop="email">
        <a-input :disabled="true" v-model="form.email" placeholder="请输入电子邮件" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.服务者.专业身份')" prop="identity">
        <a-input :disabled="true" v-model="form.identity" placeholder="请输入专业身份" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.服务者.其他')" prop="other">
        <a-input :disabled="true" v-model="form.other" placeholder="请输入其他" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.服务者.总工龄')" prop="seniority">
        <a-input :disabled="true" v-model="form.seniority" placeholder="请输入总工龄" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.服务者.公司人数')" prop="number">
        <a-input :disabled="true" v-model="form.number" placeholder="请输入公司人数" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.服务者.公司在银行信用额度')" prop="quota">
        <a-input :disabled="true" v-model="form.quota" placeholder="请输入公司再银行信用额度" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.服务者.是否推荐')" prop="isRecommend">
        <a-radio-group name="radioGroup" :default-value="0" v-model="form.isRecommend">
          <a-radio :value="true">{{ $t('经纪人/服务者.服务者.是') }}</a-radio>
          <a-radio :value="false">{{ $t('经纪人/服务者.服务者.否') }}</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.服务者.是否开启服务')" prop="isService">
        <a-radio-group name="radioGroup" :default-value="0" v-model="form.isService">
          <a-radio :value="true">{{ $t('经纪人/服务者.服务者.是') }}</a-radio>
          <a-radio :value="false">{{ $t('经纪人/服务者.服务者.否') }}</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.服务者.服务金额')" prop="money">
        <a-input :disabled="true" v-model="form.money" placeholder="请输入服务金额" />
      </a-form-model-item>
      <!--      <a-form-model-item label="头像" prop="avatar" >-->
      <!--        <a-input :disabled=true v-model="form.avatar" placeholder="请输入头像" />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item :label="$t('经纪人/服务者.服务者.评分')" prop="mark">
        <a-input :disabled="true" v-model="form.mark" placeholder="请输入评分" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.服务者.擅长')" prop="goodAt">
        <a-input :disabled="true" v-model="form.goodAt" placeholder="请输入擅长" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.服务者.详细介绍')" prop="introduce">
        <a-input :disabled="true" v-model="form.introduce" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="图片(多张图片逗号分割)" prop="picture">
        <!-- <a-input :disabled="true" v-model="form.picture" placeholder="请输入内容" type="textarea" allow-clear /> -->
        <file-upload :defaultList="filelist" @input="handleChange" :count="10" />
      </a-form-model-item>
      <a-form-model-item label="视频" prop="video">
        <!-- <a-input :disabled="true" v-model="form.video" placeholder="请输入视频" /> -->
        <file-upload type="file" :defaultList="videoList" @input="videoChange($event, 'picture')"></file-upload>
        <video
          v-if="form.video"
          style="width: 80px; height: 80px"
          class="avatar video-avatar"
          controls="controls"
          :src="form.video"
        />
      </a-form-model-item>
      <!--      <a-form-model-item label="审核状态" prop="status" >-->
      <!--        <a-radio-group name="radioGroup" :default-value="0" v-model="form.status">-->
      <!--          <a-radio :value=0>未审核</a-radio>-->
      <!--          <a-radio :value=1>已通过</a-radio>-->
      <!--          <a-radio :value=-1>可以试看</a-radio>-->
      <!--        </a-radio-group>-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="审核驳回的情况下的拒绝原因" prop="reason" >-->
      <!--        <a-input v-model="form.reason" placeholder="请输入审核驳回的情况下的拒绝原因" />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item :label="$t('经纪人/服务者.服务者.备注')" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('通用.保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('通用.取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>

    <a-form-model v-if="this.formType == 2" ref="form" :model="form" :rules="rules">
      <a-form-model-item :label="$t('通用.审核')" prop="status">
        <a-radio-group name="radioGroup" :default-value="0" v-model="form.status">
          <a-radio value="1">{{ $t('经纪人/服务者.服务者.通过') }}</a-radio>
          <a-radio value="-1">{{ $t('经纪人/服务者.服务者.拒绝') }}</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-if="form.status == -1" :label="$t('经纪人/服务者.服务者.审核拒绝原因')" prop="rejectReason">
        <a-textarea
          v-model="form.reason"
          placeholder="请输入审核拒绝原因"
          :auto-size="{ minRows: 3, maxRows: 10 }"
        ></a-textarea>
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('通用.保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('通用.取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getWaiter, addWaiter, updateWaiter } from '@/api/user/waiter'
import { getBroker } from '@/api/user/broker'
import { guid } from '@/utils/ruoyi'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      filelist: [],
      videoList: [],
      // 表单参数
      form: {
        id: null,

        userId: null,

        president: null,

        company: null,

        address: null,

        mobile: null,

        email: null,

        identity: null,

        other: null,

        seniority: null,

        number: null,

        quota: null,

        isRecommend: null,

        isService: null,

        money: null,

        avatar: null,

        mark: null,

        goodAt: null,

        introduce: null,

        picture: null,

        video: null,

        status: 0,

        reason: null,

        createTime: null,

        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        // userId: [
        //   { required: true, message: '用户id,外键{zb_user.id}不能为空', trigger: 'blur' }
        // ],
        // president: [
        //   { required: true, message: '总经理姓名不能为空', trigger: 'blur' }
        // ],
        // company: [
        //   { required: true, message: '公司名称不能为空', trigger: 'blur' }
        // ],
        // address: [
        //   { required: true, message: '公司地址不能为空', trigger: 'blur' }
        // ],
        // mobile: [
        //   { required: true, message: '电话号码不能为空', trigger: 'blur' }
        // ],
        // email: [
        //   { required: true, message: '电子邮件不能为空', trigger: 'blur' }
        // ],
        // identity: [
        //   { required: true, message: '专业身份不能为空', trigger: 'blur' }
        // ],
        // other: [
        //   { required: true, message: '其他不能为空', trigger: 'blur' }
        // ],
        // seniority: [
        //   { required: true, message: '总工龄不能为空', trigger: 'blur' }
        // ],
        // number: [
        //   { required: true, message: '公司人数不能为空', trigger: 'blur' }
        // ],
        // quota: [
        //   { required: true, message: '公司再银行信用额度不能为空', trigger: 'blur' }
        // ],
        // isRecommend: [
        //   { required: true, message: '是否推荐 0-否 1-是不能为空', trigger: 'blur' }
        // ],
        // isService: [
        //   { required: true, message: '是否开启服务 0-否 1-是不能为空', trigger: 'blur' }
        // ],
        // money: [
        //   { required: true, message: '服务金额不能为空', trigger: 'blur' }
        // ],
        // avatar: [
        //   { required: true, message: '头像不能为空', trigger: 'blur' }
        // ],
        // mark: [
        //   { required: true, message: '评分不能为空', trigger: 'blur' }
        // ],
        // goodAt: [
        //   { required: true, message: '擅长不能为空', trigger: 'blur' }
        // ],
        // introduce: [
        //   { required: true, message: '详细介绍不能为空', trigger: 'blur' }
        // ],
        // picture: [
        //   { required: true, message: '图片(多张图片逗号分割)不能为空', trigger: 'blur' }
        // ],
        // video: [
        //   { required: true, message: '视频不能为空', trigger: 'blur' }
        // ],
        status: [{ required: true, message: '审核状态，0-待审核，1-审核通过，-1审核驳回不能为空', trigger: 'blur' }],
        reason: [{ required: true, message: '审核驳回的情况下的拒绝原因不能为空', trigger: 'blur' }],
      },
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        president: null,
        company: null,
        address: null,
        mobile: null,
        email: null,
        identity: null,
        other: null,
        seniority: null,
        number: null,
        quota: null,
        isRecommend: null,
        isService: null,
        money: null,
        avatar: null,
        mark: null,
        goodAt: null,
        introduce: null,
        picture: null,
        video: null,
        status: 0,
        reason: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },

    getDetails(row, ids) {
      this.reset()
      this.formType = 1
      const id = row ? row.id : ids
      this.filelist = []
      getWaiter({ id: id }).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '详情'
      })
      row.picture.split(',').forEach((item) => {
        this.filelist.push({
          uid: guid(8, 10),
          name: item,
          status: 'done',
          halfUrl: item,
          url: item,
          path: item,
        })
      })
    },

    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getWaiter({ id: id }).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
      this.filelist = []
      this.form.video = row.video
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateWaiter(this.form)
              .then((response) => {
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            addWaiter(this.form)
              .then((response) => {
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
    handleChange(fileList) {
      // console.log('多个图片', fileList)
      const picurl = []
      fileList.map((item) => {
        picurl.push(item.url)
      })
      this.form.picture = picurl.join(',')
    },
    videoChange(info, fieldName) {
      // console.log('video', info)
      if (info.length == 0) {
        this.form.video = ''
        this.videoList = []
      }
      this.form.video = info[0].url
    },
  },
}
</script>
